#main {
}

	#main .body-content {
		max-width: 1170px;
		margin: 0 auto; 
	}
	
	#main .well img, #main .well .img-container {
		max-width: 100%;
		background-color: #ffffff;
		padding: 19px;
		margin: 10px;
		border: 1px solid #c0c0c0;
	}

	#main .well .img-container img {
		margin: 0;
		border: none;
	}

	#main .well h1, #main .well h2, #main .well h3, #main .well h4, #main .well h5, #main .well h6 {
		margin-top: 30px;
		margin-bottom: 5px;
	}

	#main .bold {
		font-weight: bold;
	}

	#main .float-right {
		float: right;
		margin-right: 0;
	}

	#main .float-left {
		float: left;
		margin-left: 0;
	}

	#main .float-center {
		display: block;
		margin-left: auto!important;
		margin-right: auto!important;
	}

	#main .full-width {
		min-width: 100%;
	}

	#main .indented-block {
		margin-left: 5%;
		width: 95%;
		margin-top: 19px;
		margin-bottom: 19px;
		border-style: solid;
		border-width: 0 0 0 5px;
		border-color: rgb(237, 237, 237);
		padding: 19px;
	}

		#main .indented-block p {
			color: rgb(100, 100, 100);
		}

		#main .indented-block.collapse {
			display: none;
		}

			#main .indented-block.collapse.show {
				display: flow-root;
			}

#main.admin .body-content {
    max-width: none;
	margin: 0 16px;
}

.well {
	border-radius: 0;
	box-shadow: rgba(0, 0, 0, 0.258824) 0px 1px 1px 0px;
	background: #fff;
	border: none;
	display: flow-root!important;
	margin-bottom: 20px;
	margin-top: 10px;
}

.well label {
    display: inline-block;
    font-weight: bold;
}

.well td, .well label, .well input {
    font-size: 0.9em;
}

.float-right {
    float: right;
    margin-right: 0;
}

.indented-block {
    margin-left: 5%;
    width: 95%;
    margin-top: 19px;
    margin-bottom: 19px;
    border-style: solid;
    border-width: 0 0 0 5px;
    border-color: rgb(237, 237, 237);
    padding: 19px;
}

.indented-block p {
    color: rgb(100, 100, 100);
}

.indented-block.collapse {
    display: none;
}

.indented-block.collapse.show {
    display: flow-root;
}

.expandLink{
    color: #337ab7;
}

.expandLink:link {
text-decoration: none;
}
  
.expandLink:visited {
text-decoration: none;
}
  
.expandLink:hover {
text-decoration: underline;
cursor: pointer;
}