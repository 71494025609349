@keyframes smallGraph {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes smallGraphInvisible {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media screen and (min-width: 1300px) {
  .smallGraph {
    transform: scale(0.9);
    position: fixed;
    top: 10px;
    left: 300px;
    z-index: 1;
    animation: smallGraph 0.4s linear forwards;
    box-shadow: 0 3px 3px -2px rgb(0 0 0 / 20%), 0 3px 4px 0 rgb(0 0 0 / 14%),
      0 1px 8px 0 rgb(0 0 0 / 12%);
    border-radius: 4px;
  }
  .smallGraphInvisible {
    animation: smallGraphInvisible 0.2s linear forwards;
    position: fixed;
    transform: scale(0.9);
    top: 10px;
    left: 20px;
    z-index: -1;
    box-shadow: 0 3px 3px -2px rgb(0 0 0 / 20%), 0 3px 4px 0 rgb(0 0 0 / 14%),
      0 1px 8px 0 rgb(0 0 0 / 12%);
    border-radius: 4px;
  }
}

@media screen and (max-width: 1300px) { 
  .smallGraphInvisible {
    display: none;
  }
  .smallGraph {
    display: none;
  }
}

#toPrint {
  padding: 0px 50px;
}

@media print {
  .section {
    page-break-before: always;
  }
  .numeric-input-field, .parameter-section {
    break-inside: avoid;
    page-break-inside: avoid;
  }
  @page { 
    size: auto;
    margin: 20mm 10mm 20mm 10mm;
  }
  body {
      margin:0;
      padding:0;
  }
}

